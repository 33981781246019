














































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getComponent } from '@/utils/helpers';
import { TASQS_LISTS } from '@/lib/constants';
import { mixins } from 'vue-class-component';
// import DataLoading from '@/lib/mixins/dataLoading';

@Component({
  components: {
    TasqItem: () => getComponent('tasqs/TasqItem'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
  },
})
export default class TasqsVerticalList extends mixins() {
  filterOpen = false;

  dataLoading = false

  completedVisible = true;

  filterVal = '';

  activeList = 'ALL';

  showSearch = false;

  typesOptions = [] as any;

  get isLoadingTasqs() {
    return tasqsListModule.isLoadingTasqs
  }

  get allTypes() {
    return [...new Set([...this.tasqs, ...this.completedTasqs].map(i => i.overriddenName))];
  }

  get listOptions() {
    return TASQS_LISTS;
  }

  get tasqs(): TasqJob[] {
    return tasqsListModule.tasqList;
  }

  get filteredCompletedTasqs(): TasqJob[] {
    let tasqs = this.completedTasqs;
    if (this.filterVal) {
      tasqs = this.completedTasqs.filter(
        (i) => i.wellName.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.engineerType.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.username.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.reassignedUserName?.toLowerCase()?.includes(this.filterVal.toLowerCase()),
      );
    }
    return tasqs.filter(t => this.typesOptions.includes(t.overriddenName));
  }

  get filteredTasqs(): TasqJob[] {
    let tasqs = this.tasqs;
    if (this.filterVal) {
      tasqs = this.tasqs.filter(
        (i) => i.wellName.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.engineerType.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.username.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.reassignedUserName?.toLowerCase()?.includes(this.filterVal.toLowerCase()),
      );
    }
    return tasqs.filter(t => this.typesOptions.includes(t.overriddenName));
  }

  get completedTasqs() {
    return tasqsListModule.completedTasqList;
  }

  async changeTasqList() {
    const list = this.listOptions.find(i => i.val === this.activeList) as {text: string, val: string};
    // this.filterOpen = false;
    tasqsListModule.setActiveTasq('');
    this.$router.push({ name: 'Tasqs' });
    await tasqsListModule.getTasqs(list);
  }

  @Watch('allTypes')
  updateTypesOptions() {
    this.typesOptions = [...this.allTypes];
  }
}
